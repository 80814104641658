<template>
	<v-server-table ref="demoRequestsTable" :columns="columns" :options="options">
		<div slot="Full Name" slot-scope="props">
			<span>{{ props.row.contact_fname }} {{ props.row.contact_lname }}</span>
		</div>
		<div slot="Email" slot-scope="props">
			<span>{{ props.row.corporate_email }}</span>
		</div>
		<div slot="Phone Number" slot-scope="props">
			<span>{{ props.row.corporate_phone }}</span>
		</div>
		<div slot="Company Name" slot-scope="props">
			<span>{{ props.row.corporate_name }}</span>
		</div>
		<div slot="Staff Strength" slot-scope="props">
			<span>{{ props.row.staff_strength }}</span>
		</div>
		<div slot="Request Date" slot-scope="props">
			<span>{{ props.row.created_at | date('d MMMM, yyyy') }}</span>
		</div>
	</v-server-table>
</template>

<script>
export default {
  props: {
    requestFunction: Function,
    showActions: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      columns: [
        'Full Name',
        'Email',
        'Phone Number',
        'Company Name',
        'Staff Strength',
        'Request Date'
      ],
      options: {
        filterable: false,
        requestFunction: (data) => {
          return this.requestFunction(data)
        },
        perPage: 10
      }
    }
  },
  created () {
    if (this.showActions) {
      this.columns.push('Actions')
    }
  },
  methods: {}
}
</script>
