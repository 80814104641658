<template>
	<div>
		<h2 class="header-title">All Demo Requests</h2>
		<div class="row mt-4">
			<div class="col-12">
				<div class="card">
					<div class="col-auto d-flex justify-content-end align-items-end px-0">
						<download-button v-if="tableData.length" :downloading="downloadingReport" label="Download Report"
							@download="downloadReport()">
						</download-button>
					</div>
					<demo-requests-list :request-function="(params) => fetchDemoRequests(params)"
						:show-actions="false"></demo-requests-list>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
import DemoRequestsList from '@/components/modules/promoCode/DemoRequestsList'
import moment from 'moment'
import { ref, inject } from 'vue'
import { ExportToCsv } from 'export-to-csv'
import DownloadButton from '@/components/core/DownloadButton.vue'
import { extractErrorMessage } from '@/utils/helpers'
import { axiosInstance } from '@/plugins/axios'
import Swal from 'sweetalert2'


const search = ref('')
const tableData = ref([])
const totalRecords = ref(0)
const downloadingReport = ref(false)

const fetchDemoRequests = async (params) => {
  const limit = params ? params.limit : 10
  const page = params ? params.page : 1

  const offset = (page - 1) * limit

  const baseUrl = '/v1/prospective-corporates'

  const url = `${baseUrl}?limit=${limit}&offset=${offset}&order=created_at:desc`

  const result = await axiosInstance.get(url)
  tableData.value = result?.data?.data
  totalRecords.value = result.data?.pagination?.rowCount
  const newResult = {
    ...result.data,
    data: result.data.data,
    count: result.data.pagination.rowCount
  }

  return {
    data: newResult
  }
}

const downloadReport = async () => {
  try {
    downloadingReport.value = true
    const result = await axiosInstance.get(
      `/v1/prospective-corporates?limit=${totalRecords.value}&order=created_at:desc`
    )
    const csvData = result?.data?.data.map((data) => {
      return {
        fullName: data.contact_fname + ' ' + data.contact_lname,
        corporate_emails: data.corporate_email,
        corporate_phone: data.corporate_phone,
        corporate_name: data.corporate_name,
        company_staff_strength: data.staff_strength,
        request_date: moment(data.created_at).format('MMMM Do YYYY')
      }
    })

    const csvParams = {
      fieldSeparator: ',',
      filename: 'Intrested Companies List',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: true,
      title: 'Intrested Companies List',
      useTextFile: false,
      useBom: true,
      headers: [
        'Full Name',
        'Corporate Emails',
        'Corporate Phone',
        'Corporate Name',
        'Corporate Staff Strength',
        'Request Date'
      ]
    }

    const csvExporter = new ExportToCsv(csvParams)

    csvExporter.generateCsv(csvData)
    Swal.fire({
      icon: 'success',
      title: 'Report downloaded',
      text: 'Report has been downloaded successfully',
      showCloseButton: true
    })
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'An error occurred!',
      text: extractErrorMessage(error, 'Failed to download report'),
      showCloseButton: true
    })
  } finally {
    downloadingReport.value = false
  }
}
</script>

<style scoped></style>
